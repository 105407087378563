import { parseGenericPage } from 'utils/parsers';
import CustomMetaData, {
  CustomMetaDataProps,
} from 'components/general/customMetaData/CustomMetaData';

import { MainFooter } from 'components/mainFooter/MainFooter';
import style from './index.module.scss';
import { useGlobalContentContext } from 'context/GlobalContentContext';
import { useEffect } from 'react';
import Link from 'next/link';
import { GenericPageLayoutProps } from 'components/general/genericPageLayout/GenericPageLayout';
import Image, { ImageProps } from 'next/image';

type Props = {
  generic: GenericPageLayoutProps;
  metaData: CustomMetaDataProps;
};

const Index = ({ generic, metaData }: Props): JSX.Element => {
  const { setData } = useGlobalContentContext();
  useEffect(() => {
    setData(generic.globalData);
  }, []);
  const { projects } = generic.globalData;
  return (
    <div className={style.container}>
      <CustomMetaData {...metaData} />
      <div className={style.mobileLayout}>
        <div className={style.grid}>
          {projects.map((project) => {
            const imageProps: ImageProps = project.hero.phone_image
              ? {
                  src: project.hero.phone_image,
                  layout: 'fill',
                  objectFit: 'cover',
                }
              : {
                  src: project.hero.image,
                  layout: 'fill',
                  objectFit: 'cover',
                };

            return (
              <div key={project.slug} className={style.workItem}>
                <Link href={`work/${project.slug}`}>
                  <a className={style.imageContainer}>
                    <div className={style.imageHolder}>
                      <Image className={``} {...imageProps} draggable={false} />
                    </div>
                    <h2
                      className={style.projectTitle}
                      dangerouslySetInnerHTML={{ __html: project.title }}
                    ></h2>
                  </a>
                </Link>
              </div>
            );
          })}
        </div>
        <MainFooter showMenu={false} showSocial={true} />
      </div>
    </div>
  );
};

export async function getStaticProps(context) {
  const genericProps: GenericPageLayoutProps = await parseGenericPage('/homepage/', context);

  const props: Props = {
    generic: genericProps,

    metaData: {
      title: genericProps.originalResponse.meta_title,
      description: genericProps.originalResponse.meta_description,
      image: genericProps.originalResponse.meta_image,
    },
  };
  // Pass data to the page via props
  return {
    // TODO: how can we add Typescript here?
    props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: NEXTJS_REVALIDATE_PAGE_IN_SECONDS, // In seconds
  };
}

export const NEXTJS_REVALIDATE_PAGE_IN_SECONDS = 10;
export default Index;
